// eslint-disable-next-line no-unused-vars
import React from 'react'
import MLSNavbar from '../Navbar/MLSNavbar'


const News = () => {
  return (
    <div>
      <MLSNavbar />
    </div>
  )
}

export default News